import { INewsletter2 } from "../../Types";
import NewsLetterActionBar from "../../components/ActionBar/NewsLetterActionBar";

export const newsletterColumns: TTableColumns<INewsletter2>[] = [
  {
    key: "title",
    label: "Title",
    minWidth: "20%",
    renderCell: (value) => value.title,
  },
  {
    key: "date",
    label: "Date",
    minWidth: "15%",
    renderCell: (value) => new Date(value.date).toLocaleDateString(),
  },
  {
    key: "img",
    label: "Page Image",
    minWidth: "15%",
    renderCell: (value: any) => (
      <img
        src={value.pageData[0].left.img}
        alt="Card"
        style={{ maxWidth: 100 }}
      />
    ),
  },
  {
    key: "actions",
    label: "Actions",
    minWidth: "15%",
    renderCell: (value) => <NewsLetterActionBar value={value} />,
  },
];
