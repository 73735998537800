import React, { useEffect } from "react";
import { useForm } from "@mantine/form";
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Text,
  Textarea,
  TextInput,
  Checkbox,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { yupResolver } from "@mantine/form";
import { useNavigate, useParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import ImageCropInput2 from "../../container/ImageCropPicker/ImageCropInput2";
import RichTextEditorWrapper from "../RichTextEditor/RichTextEditorWrapper";
import { IFormData } from "../../Types";

interface IGenericFormProps {
  validationSchema: any;
  useFetchById: (params: { _id: string }, isEditing: boolean) => any;
  useCreateMutation: () => any;
  useUpdateMutation: () => any;
  entityName: string;
  displayName: string;
}

const GenericForm: React.FC<IGenericFormProps> = ({
  validationSchema,
  useFetchById,
  useCreateMutation,
  useUpdateMutation,
  entityName,
  displayName,
}) => {
  const { id } = useParams();
  const isEditing = Boolean(id);

  const { data: entityData } = useFetchById({ _id: id ?? "" }, isEditing);
  const { mutateAsync: createEntity } = useCreateMutation();
  const { mutateAsync: updateEntity } = useUpdateMutation();
  const navigate = useNavigate();

  const initialValues: IFormData = {
    date: new Date(),
    ...(entityName === "newsletter" && { title: "" }),
    cardData: {
      cardImg: null,
      title: "",
      description: "",
    },
    pageData: [
      {
        heading: "",
        description: "",
        left: { img: null, divider: false, tag: "" },
        ...(entityName === "research" && {
          author: "", // Include author for research entity
          hashTag: "", // Include hashTag for research entity
        }),
      },
    ],
    isActive: true,
  };

  const form = useForm<IFormData>({
    mode: "controlled",
    initialValues,
    validate: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (isEditing && entityData && entityData.data.length > 0) {
      const data = entityData.data[0];

      if (data) {
        const validDate = data.date ? new Date(data.date) : new Date();
        const isValidDate = !isNaN(validDate.getTime());

        const pageDataWithAuthorHashtag = (data.pageData || []).map(
          (page: any) => ({
            heading: page.heading || "",
            description: page.description || "",
            left: {
              img: page.left?.img || null,
              divider: page.left?.divider || false,
              tag: page.left?.tag || "",
            },
            ...(entityName === "research" && {
              author: page.author || "",
              hashTag: page.hashTag || "",
            }),
          })
        );

        form.setValues({
          date: isValidDate ? validDate : new Date(),
          ...(entityName === "newsletter" && { title: data.title }),
          cardData: {
            cardImg: data.cardData?.cardImg || null,
            title: data.cardData?.title || "",
            description: data.cardData?.description || "",
          },
          pageData: pageDataWithAuthorHashtag,
          isActive: data.isActive ?? true,
        });
      }
    }
  }, [isEditing, entityData?.data, entityName]);

  const addPage = () => {
    form.insertListItem("pageData", {
      heading: "",
      description: "",
      left: { img: null, divider: false, tag: "" },
    });
  };

  const removePage = (index: number) => {
    form.removeListItem("pageData", index);
  };

  const onSubmit = async (values: IFormData) => {
    try {
      if (isEditing) {
        const res = await updateEntity({ data: form.values, id: id ?? "" });
        if (res.status === "success") {
          form.reset();
          navigate(`/${entityName}`);
          showNotification({
            title: `${displayName} Updated Successfully`,
            message: "Done",
            color: "green",
          });
        }
      } else {
        const res = await createEntity({ data: form.values });
        if (res.status === "success") {
          form.reset();
          navigate(`/${entityName}`);
          showNotification({
            title: `${displayName} Added Successfully`,
            message: "Done",
            color: "green",
          });
        }
        if (res.status === 400) {
          showNotification({
            title: "Image size is too large.",
            message: "Max limit for image upload is 2MB",
            color: "red",
          });
        }
      }
    } catch (error: any) {
      // Handle and display the error message
      // console.log("error occured");
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      showNotification({
        title: "Error",
        message: errorMessage,
        color: "red",
      });
    }
  };

  return (
    <Box style={{ maxWidth: 600, margin: "4rem", paddingTop: "2rem" }}>
      <Text style={{ fontSize: "1.5rem", fontWeight: "400" }}>
        Add {displayName} Details
      </Text>
      <form
        style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
        onSubmit={form.onSubmit(onSubmit)}
      >
        <Flex gap={"xl"}>
          <DateInput
            label="Date"
            {...form.getInputProps("date")}
            withAsterisk
            placeholder="Pick a Date"
          />
          {entityName !== "newsletter" && (
            <ImageCropInput2
              form={form}
              name="cardData.cardImg"
              label="Card Image"
              // placeholder={
              //   isEditing && entityData?.data[0].cardData?.cardImg
              //     ? entityData?.data[0].cardData.cardImg
              //     : "Upload Image"
              // }
              accept="image/*"
              aspectRatio={293 / 210}
            />
          )}
        </Flex>
        {entityName === "newsletter" ? (
          <TextInput
            label="Title"
            {...form.getInputProps("title")}
            placeholder="Enter title"
            withAsterisk
          />
        ) : (
          <TextInput
            label="Title"
            {...form.getInputProps("cardData.title")}
            placeholder="Enter title"
            withAsterisk
          />
        )}

        {entityName !== "newsletter" && (
          <>
            <Text style={{ fontWeight: "500" }}>Description</Text>
            <RichTextEditorWrapper
              value={form.values.cardData?.description || ""}
              onChange={(value) => {
                if (!form.values.cardData) {
                  form.setFieldValue("cardData", {
                    title: "",
                    description: "",
                    cardImg: null,
                  });
                }
                form.setFieldValue("cardData.description", value);
              }}
            />
          </>
        )}
        {form.values.pageData.map((page, index) => (
          <Box key={index}>
            <Flex direction={"column"} gap={"1rem"}>
              {index === 0 && (
                <Box style={{ marginTop: "2rem" }}>
                  <Text style={{ fontSize: "1.5rem", fontWeight: "400" }}>
                    Page Details
                  </Text>
                  <TextInput
                    label="Page Heading"
                    {...form.getInputProps(`pageData.${index}.heading`)}
                    placeholder="Enter page heading"
                    style={{ paddingTop: "1rem" }}
                  />
                  {entityName === "research" ? (
                    <>
                      <TextInput
                        label="Author Name"
                        {...form.getInputProps(`pageData.${index}.author`)}
                        placeholder="Enter Author Name"
                        withAsterisk
                        style={{ paddingTop: "1rem" }}
                      />
                      {/* <TextInput
                        label="Hashtag"
                        {...form.getInputProps(`pageData.${index}.hashTag`)}
                        placeholder="Enter Hashtag for the research"
                        withAsterisk
                        style={{ paddingTop: "1rem" }}
                      /> */}
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              )}
              <Text style={{ fontWeight: "500" }}>Section Description</Text>
              <RichTextEditorWrapper
                value={form.values.pageData[index].description}
                onChange={(value: any) =>
                  form.setFieldValue(`pageData.${index}.description`, value)
                }
              />
              <Flex align={"center"} gap={"lg"}>
                <Flex direction={"column"}>
                  <ImageCropInput2
                    form={form}
                    name={`pageData.${index}.left.img`}
                    label="Left Image"
                    accept="image/*"
                    aspectRatio={
                      index === 0 && entityName === "research"
                        ? 522 / 724
                        : index === 0 && entityName !== "research"
                        ? 522 / 354
                        : 418 / 283
                    }
                  />
                  {/* {page.left.img && (
                    <Button
                      style={{ width: "fit-content" }}
                      mt="sm"
                      color="red"
                      onClick={() => {
                        form.setFieldValue(`pageData.${index}.left.img`, null);
                      }}
                      variant="light"
                    >
                      Remove Image
                    </Button>
                  )} */}
                </Flex>
                <Checkbox
                  label="Divider"
                  {...form.getInputProps(`pageData.${index}.left.divider`, {
                    type: "checkbox",
                  })}
                  style={{ paddingTop: "1rem" }}
                />
              </Flex>
              <TextInput
                label="Tag"
                {...form.getInputProps(`pageData.${index}.left.tag`)}
                placeholder="Enter tag"
              />
              {index > 0 && (
                <Button
                  mt="sm"
                  color="red"
                  onClick={() => removePage(index)}
                  variant="light"
                >
                  {form.values.pageData.length > 1
                    ? "Remove Section"
                    : "Remove Page"}
                </Button>
              )}
              <Divider my="sm" />
            </Flex>
          </Box>
        ))}
        <Group mt="md">
          <Button onClick={addPage}>
            {form.values.pageData.length === 0 ? "Add Page" : "Add Section"}
          </Button>
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Box>
  );
};

export default GenericForm;
