import React from "react";
import { blogValidation } from "../../../validation/blogs.validation";
import GenericForm from "../../../components/ContentForm/GenericForm";
import { useCreateTalkMutation } from "../../../hooks/talks/mutations/useCreateTalk";
import { useGetTalkById } from "../../../hooks/talks/queries/useGetTalkById";
import { useUpdateTalkMutation } from "../../../hooks/talks/mutations/useUpdateTalk";

const TalkAddForm: React.FC = () => {
  return (
    <GenericForm
      entityName="talk"
      displayName="Talk"
      useCreateMutation={useCreateTalkMutation}
      useFetchById={useGetTalkById}
      useUpdateMutation={useUpdateTalkMutation}
      validationSchema={blogValidation}
    />
  );
};

export default TalkAddForm;
