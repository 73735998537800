import React from "react";
import { useCreateBlogsMutation } from "../../../hooks/blogs/mutations/useCreateBlogs";
import { blogValidation } from "../../../validation/blogs.validation";
import { useGetBlogById } from "../../../hooks/blogs/queries/useGetBlogById";
import { useUpdateBlogMutation } from "../../../hooks/blogs/mutations/useUpdateBlog";
import GenericForm from "../../../components/ContentForm/GenericForm";

const BlogAddForm: React.FC = () => {
  return (
    <GenericForm
      entityName="blogs"
      displayName="Blog"
      useCreateMutation={useCreateBlogsMutation}
      useFetchById={useGetBlogById}
      useUpdateMutation={useUpdateBlogMutation}
      validationSchema={blogValidation}
    />
  );
};

export default BlogAddForm;
