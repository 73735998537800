import React, { memo, useEffect, useRef, useState } from "react";
import { useForm, yupResolver } from "@mantine/form";
import {
  Button,
  TextInput,
  Checkbox,
  FileInput,
  Box,
  Group,
  Divider,
  Flex,
  Text,
  Textarea,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IEvent } from "../../../Types";
import styles from "./indes.module.css";
import RichTextEditorWrapper from "../../../components/RichTextEditor/RichTextEditorWrapper";
import { showNotification } from "@mantine/notifications";
import { useNavigate, useParams } from "react-router-dom";
import ImageCropInput2 from "../../../container/ImageCropPicker/ImageCropInput2";
import { eventValidation } from "../../../validation/events.validation";
import { ActionIcon, rem } from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { IconClock, IconTrash } from "@tabler/icons-react";
import { useGetSingleEvent } from "../../../hooks/events/queries/useGetSingleEvent";
import { useCreateEventMutation } from "../../../hooks/events/mutations/useCreateEvent";
import { useUpdateEventMutation } from "../../../hooks/events/mutations/useUpdateStory";
interface Iprops {
  name: string;
}
const StoryAddForm: React.FC = () => {
  const { id } = useParams();
  const isEditing = Boolean(id);

  const { data: eventData } = useGetSingleEvent({ _id: id ?? "" }, isEditing);

  const { mutateAsync, isPending } = useCreateEventMutation();
  const { mutateAsync: updateEvent } = useUpdateEventMutation();
  const navigate = useNavigate();
  const form = useForm<IEvent>({
    mode: "controlled",
    initialValues: {
      startDate: new Date(),
      endDate: new Date(),
      cardData: {
        cardImg: null,
        title: "",
        description: "",
        time: "",
        venue: "",
      },
      pageData: [
        {
          heading: "",
          description: "",
          left: { img: null, divider: false, tag: "" },
        },
      ],
      isActive: true,
    },
    validate: yupResolver(eventValidation),
  });
  useEffect(() => {
    if (isEditing && eventData && eventData.data.length > 0) {
      const data = eventData.data[0];

      if (data) {
        const validStartDate = data.startDate
          ? new Date(data.startDate)
          : new Date();
        const validEndDate = data.endDate ? new Date(data.endDate) : new Date();
        const isValidStartDate = !isNaN(validStartDate.getTime());
        const isValidEndDate = !isNaN(validEndDate.getTime());

        form.setValues({
          startDate: isValidStartDate ? validStartDate : new Date(),
          endDate: isValidEndDate ? validEndDate : new Date(),
          cardData: {
            cardImg: data.cardData?.cardImg || null,
            title: data.cardData?.title || "",
            description: data.cardData?.description || "",
            time: data.cardData?.time || "",
            venue: data.cardData?.venue || "",
          },
          pageData: (data.pageData || []).map((page: any) => ({
            heading: page.heading || "",
            description: page.description || "",
            left: {
              img: page.left?.img || null,
              divider: page.left?.divider || false,
              tag: page.left?.tag || "",
            },
          })),
          isActive: data.isActive ?? true,
        });
      }
    }
  }, [isEditing, eventData?.data]);

  const addPage = () => {
    form.insertListItem("pageData", {
      heading: "",
      description: "",
      left: { img: null, divider: false, tag: "" },
    });
  };

  const removePage = (index: number) => {
    form.removeListItem("pageData", index);
  };

  const onSubmit = async (values: IEvent) => {
    if (isEditing) {
      const res = await updateEvent({ data: form.values, id: id ?? "" });
      if (res.status === "success") {
        form.reset();
        navigate("/events");
        showNotification({
          title: "Event Updated Successfully",
          message: "Done",
          color: "green",
        });
      }
    } else {
      const formData = new FormData();
      formData.append("startDate", values.startDate.toISOString());
      formData.append("endDate", values.endDate.toISOString());
      if (values.cardData.cardImg && values.cardData.cardImg.length > 0) {
        formData.append("cardImg", values.cardData.cardImg[0]);
      }
      formData.append("title", values.cardData.title);
      formData.append("description", values.cardData.description);
      formData.append("venue", values.cardData.venue ?? "");
      formData.append("time", values.cardData.time?.toString() ?? "");
      values.pageData.forEach((page, index) => {
        formData.append(`pageData[${index}][heading]`, page.heading);
        formData.append(`pageData[${index}][description]`, page.description);
        if (page.left.img && page.left.img.length > 0) {
          formData.append(`pageData[${index}][left][img]`, page.left.img[0]);
        }
        formData.append(
          `pageData[${index}][left][divider]`,
          page.left.divider.toString()
        );
        formData.append(`pageData[${index}][left][tag]`, page.left.tag);
        formData.append("isActive", values.isActive.toString());
      });
      const res = await mutateAsync({ data: form.values });
      if (res.status === "success") {
        form.reset();
        navigate("/events");
        showNotification({
          title: "Event Added Successfully",
          message: "Done",
          color: "green",
        });
      }
    }
  };
  const ref = useRef<HTMLInputElement>(null);
  const pickerControl = (
    <ActionIcon
      variant="subtle"
      color="gray"
      onClick={() => ref.current?.showPicker()}
    >
      <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
    </ActionIcon>
  );
  return (
    <Box style={{ maxWidth: 600, margin: "4rem", paddingTop: "2rem" }}>
      <Text style={{ fontSize: "1.5rem", fontWeight: "400" }}>
        Add Event Details
      </Text>
      <form
        style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
        className={styles.form}
        onSubmit={form.onSubmit(onSubmit)}
      >
        <Flex gap={"xl"}>
          <DateInput
            label="Start Date"
            {...form.getInputProps("startDate")}
            withAsterisk
            placeholder="Pick a Date"
          />
          <DateInput
            label="End Date"
            {...form.getInputProps("endDate")}
            withAsterisk
            placeholder="Pick a Date"
          />
        </Flex>
        <ImageCropInput2
          form={form}
          name="cardData.cardImg"
          label="Card Image"
          accept="image/*"
          aspectRatio={293 / 210}
        />
        <TextInput
          label="Title"
          {...form.getInputProps("cardData.title")}
          placeholder="Enter title"
          withAsterisk
        />
        <Text style={{ fontWeight: "500" }}>Description</Text>
        <RichTextEditorWrapper
          value={form.values.cardData?.description || ""}
          onChange={(value) => {
            if (!form.values.cardData) {
              form.setFieldValue("cardData", {
                title: "",
                description: "",
                time: "",
                venue: "",
                cardImg: null,
              });
            }
            form.setFieldValue("cardData.description", value);
          }}
        />
        <TextInput
          label="Venue"
          {...form.getInputProps("cardData.venue")}
          placeholder="Enter location of the event"
        />
        <TimeInput
          label="Event Timing"
          {...form.getInputProps("cardData.time")}
          placeholder="Enter Time"
          ref={ref}
          rightSection={pickerControl}
        />
        {form.values.pageData.map((page, index) => (
          <Box key={index}>
            <Flex direction={"column"} gap={"1rem"}>
              {index === 0 && (
                <Box style={{ marginTop: "2rem" }}>
                  <Text style={{ fontSize: "1.5rem", fontWeight: "400" }}>
                    Page Details
                  </Text>
                  <TextInput
                    label="Page Heading"
                    {...form.getInputProps(`pageData.${index}.heading`)}
                    placeholder="Enter page heading"
                    withAsterisk
                    style={{ paddingTop: "1rem" }}
                  />
                </Box>
              )}
              <Text style={{ fontWeight: "500" }}>Section Description</Text>
              <RichTextEditorWrapper
                value={form.values.pageData[index].description}
                onChange={(value) =>
                  form.setFieldValue(`pageData.${index}.description`, value)
                }
              />
              <Flex align={"center"} gap={"lg"}>
                <ImageCropInput2
                  form={form}
                  name={`pageData.${index}.left.img`}
                  label="Left Image"
                  // placeholder={
                  //   isEditing && page.left.img
                  //     ? page.left.img.toString()
                  //     : "Upload Image"
                  // }
                  accept="image/*"
                  aspectRatio={index == 0 ? 522 / 354 : 418 / 283}
                />
                <Checkbox
                  label="Divider"
                  {...form.getInputProps(`pageData.${index}.left.divider`, {
                    type: "checkbox",
                  })}
                  style={{ paddingTop: "1rem" }}
                />
              </Flex>
              <TextInput
                label="Tag"
                {...form.getInputProps(`pageData.${index}.left.tag`)}
                placeholder="Enter tag"
              />
              {index > 0 && (
                <Button
                  mt="sm"
                  color="red"
                  onClick={() => removePage(index)}
                  variant="light"
                >
                  {form.values.pageData.length > 1
                    ? "Remove Section"
                    : "Remove Page"}
                </Button>
              )}
              <Divider my="sm" />
            </Flex>
          </Box>
        ))}
        <Group mt="md">
          <Button onClick={addPage}>
            {form.values.pageData.length === 0 ? "Add Page" : "Add Section"}
          </Button>
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Box>
  );
};

export default memo(StoryAddForm);
